/* eslint-disable global-require */

const localeData = [
  ...require('react-intl/locale-data/en'),
  ...require('react-intl/locale-data/fr'),
]

const languages = [
  { value: 'en', isDefault: true },
  { value: 'fr', isDefault: false },
]

module.exports = {
  localeData,
  languages,
}
